import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "main",
  style: {"padding-bottom":"100px"}
}
const _hoisted_2 = {
  class: "section",
  style: {"padding":"25px","border-radius":"1rem","min-width":"400px","box-shadow":"0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1)"}
}
const _hoisted_3 = { class: "text-black" }
const _hoisted_4 = { class: "text-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_spin, { spinning: _ctx.isLoading }, {
        default: _withCtx(() => [
          _cache[7] || (_cache[7] = _createElementVNode("h1", {
            class: "card-title text-center",
            id: "account-recovery"
          }, "Daily Wet Stock", -1)),
          _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "text-center" }, "Email Sent!", -1)),
          _createElementVNode("p", _hoisted_3, [
            _cache[2] || (_cache[2] = _createTextVNode("We sent a message to ")),
            _createElementVNode("b", null, _toDisplayString(_ctx.email), 1),
            _cache[3] || (_cache[3] = _createTextVNode(" so you can pick your new password."))
          ]),
          _createElementVNode("p", _hoisted_4, [
            _cache[4] || (_cache[4] = _createTextVNode("Didn't get an email? Contact our admin support ")),
            _createElementVNode("b", null, _toDisplayString(_ctx.supportEmail), 1)
          ]),
          _createVNode(_component_a_form, {
            id: "components-form-demo-normal-login",
            model: _ctx.userState,
            labelCol: { span: 30 },
            wrapperCol: { span: 25 }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_a_button, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.tryAgain())),
                      type: "default",
                      loading: _ctx.loading
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode(" Try again ")
                      ])),
                      _: 1
                    }, 8, ["loading"]),
                    _createVNode(_component_a_button, {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.login())),
                      type: "primary",
                      loading: _ctx.loading,
                      class: "ml-4px"
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode(" Login ")
                      ])),
                      _: 1
                    }, 8, ["loading"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"])
        ]),
        _: 1
      }, 8, ["spinning"])
    ])
  ]))
}