import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "main",
  style: {"padding-bottom":"100px"}
}
const _hoisted_2 = {
  class: "section",
  style: {"padding":"25px","border-radius":"1rem","min-width":"400px","box-shadow":"0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1)"}
}
const _hoisted_3 = { style: {"display":"flex","justify-content":"space-between"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserOutlined = _resolveComponent("UserOutlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_LockOutlined = _resolveComponent("LockOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("h1", {
        class: "card-title text-center",
        id: "login"
      }, "Daily Wet Stock", -1)),
      _createVNode(_component_a_form, {
        id: "components-form-demo-normal-login",
        model: _ctx.userState,
        labelCol: { span: 30 },
        wrapperCol: { span: 25 },
        onFinish: _ctx.handleFinish,
        onFinishFailed: _ctx.handleFinishFailed
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                type: "email",
                placeholder: "Email",
                value: _ctx.userState.email,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userState.email) = $event)),
                required: ""
              }, {
                prefix: _withCtx(() => [
                  _createVNode(_component_UserOutlined, { style: {"color":"rgba(0, 0, 0, 0.25)"} })
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                type: "password",
                placeholder: "Password",
                value: _ctx.userState.password,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userState.password) = $event)),
                required: ""
              }, {
                prefix: _withCtx(() => [
                  _createVNode(_component_LockOutlined, { style: {"color":"rgba(0, 0, 0, 0.25)"} })
                ]),
                _: 1
              }, 8, ["value"]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_router_link, { to: "/account-recovery" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, { type: "link" }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Forgot Password?")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                class: "login-form-button",
                "html-type": "submit",
                type: "primary",
                loading: _ctx.loading
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" Sign In ")
                ])),
                _: 1
              }, 8, ["loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "onFinish", "onFinishFailed"])
    ])
  ]))
}