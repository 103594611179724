import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { title: "Dashboard" }, {
    default: _withCtx(() => [
      (_ctx.isAdmin)
        ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                xs: {span: 24},
                lg: {span: 10}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "Select Retailer" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        id: "retailer_id",
                        "show-search": "",
                        value: _ctx.retailer_id,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.retailer_id) = $event)),
                        required: "",
                        onChange: _ctx.onChange,
                        filterOption: _ctx.filterOption
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_retailers, (item) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: item.id
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.full_name), 1)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value", "onChange", "filterOption"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.retailer_id)
                ? (_openBlock(), _createBlock(_component_a_col, {
                    key: 0,
                    xs: {span: 24},
                    lg: {span: 10, offset: 1}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { label: "Select Site" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            id: "site_id",
                            value: _ctx.site_id,
                            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.site_id) = $event)),
                            required: "",
                            onChange: _ctx.getCalendarData,
                            "show-search": "",
                            filterOption: _ctx.filterOption
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_retailer_sites, (item) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: item.id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.site_name), 1)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "onChange", "filterOption"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_a_row, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                xs: {span: 24},
                lg: {span: 10}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "Select Site" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        id: "site_id",
                        value: _ctx.site_id,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.site_id) = $event)),
                        required: "",
                        onChange: _ctx.getCalendarData,
                        "show-search": "",
                        filterOption: _ctx.filterOption
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_retailer_sites, (item) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: item.id
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.site_name), 1)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value", "onChange", "filterOption"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })),
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            xs: {span: 24},
            lg: {span: 10}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    span: 24,
                    class: "legend-div"
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createElementVNode("div", { class: "legends posted-legend" }, null, -1),
                      _createTextVNode(" All products have posted inventory ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_col, {
                    span: 24,
                    class: "legend-div"
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createElementVNode("div", { class: "legends missing-legend" }, null, -1),
                      _createTextVNode(" 1 or more products have not been posted ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            xs: {span: 24},
            lg: {span: 12, offset: 1}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    span: 24,
                    class: "legend-div"
                  }, {
                    default: _withCtx(() => [
                      _cache[5] || (_cache[5] = _createElementVNode("strong", null, "Date: ", -1)),
                      _createTextVNode(_toDisplayString(_ctx.day === '' ? '-' : _ctx.day), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            xs: {span: 24},
            lg: {span: 10}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_spin, { spinning: _ctx.isCalendarLoading }, {
                default: _withCtx(() => [
                  _createVNode(_component_Calendar, {
                    value: null,
                    color: "red",
                    attributes: _ctx.attribute,
                    "is-expanded": "",
                    "max-date": new Date(),
                    onTransitionStart: _ctx.getCalendarData,
                    ref: "calendar",
                    onDayclick: _ctx.handleDayClick
                  }, null, 8, ["attributes", "max-date", "onTransitionStart", "onDayclick"])
                ]),
                _: 1
              }, 8, ["spinning"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            xs: {span: 24},
            lg: {span: 12, offset: 1},
            style: {"text-align":"left"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_spin, { spinning: _ctx.isInfoLoading }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_table, {
                    "row-key": "id",
                    columns: _ctx.columns,
                    "data-source": _ctx.productStatus,
                    pagination: { pageSize: 50 },
                    scroll: { y: 240 }
                  }, {
                    status: _withCtx(({record}) => [
                      _createVNode(_component_a_tag, {
                        color: record.status === 'Posted' ? 'green' : 'red'
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(record.status), 1)
                        ]),
                        _: 2
                      }, 1032, ["color"])
                    ]),
                    _: 1
                  }, 8, ["columns", "data-source"])
                ]),
                _: 1
              }, 8, ["spinning"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}