import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "main",
  style: {"padding-bottom":"100px"}
}
const _hoisted_2 = {
  class: "section",
  style: {"padding":"25px","border-radius":"1rem","min-width":"400px","box-shadow":"0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1)"}
}
const _hoisted_3 = { style: {"display":"flex"} }
const _hoisted_4 = { style: {"text-align":"right","width":"38%"} }
const _hoisted_5 = {
  style: {"display":"flex"},
  class: "div-go-back-to-login"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MailOutlined = _resolveComponent("MailOutlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_spin, { spinning: _ctx.isLoading }, {
        default: _withCtx(() => [
          _cache[4] || (_cache[4] = _createElementVNode("h1", {
            class: "card-title text-center",
            id: "account-recovery"
          }, "Daily Wet Stock", -1)),
          _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "text-center" }, "Account Recovery", -1)),
          _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-black" }, "This helps show that this account really belongs to you.", -1)),
          _createVNode(_component_a_form, {
            id: "components-form-demo-normal-login",
            model: _ctx.userState,
            labelCol: { span: 30 },
            wrapperCol: { span: 25 },
            onFinish: _ctx.handleSubmit
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    type: "email",
                    placeholder: "Enter your email address",
                    value: _ctx.userState.email,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userState.email) = $event)),
                    required: ""
                  }, {
                    prefix: _withCtx(() => [
                      _createVNode(_component_MailOutlined, { style: {"color":"rgba(0, 0, 0, 0.25)"} })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-left" }, [
                      _createElementVNode("span", { class: "text-muted" }, "We'll send forget password link on your email. "),
                      _createElementVNode("br")
                    ], -1)),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_a_button, {
                        class: "submit-form-button",
                        "html-type": "submit",
                        type: "primary",
                        loading: _ctx.loading
                      }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode(" Submit ")
                        ])),
                        _: 1
                      }, 8, ["loading"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_router_link, { to: "/login" }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createElementVNode("a", { class: "returning-text" }, [
                          _createElementVNode("small", null, "Go back to login..")
                        ], -1)
                      ])),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "onFinish"])
        ]),
        _: 1
      }, 8, ["spinning"])
    ])
  ]))
}